import {
	addNewRelicPageAction,
	setNewRelicCustomAttribute
} from 'ddc-new-relic';
import { hysteriFetch, wrappers } from 'hysterics';
import {
	urlizeFacetObj,
	getUrlParams,
	filterNonInventoryParams,
	findSBParamsMappingKey
} from '../utilities/url-params';
import { setDataLayerTracking } from '../utilities/tracking';
import { logOnServer } from '../utilities/fetch-endpoint';
import { geoLocationObj } from '../global-constants';
import { trackFetchDuration } from '../utilities/WISutils';
import { removeDeprecatedFacets } from '../utilities/removeDeprecatedFacets';

export class InvData {
	constructor(
		flags,
		requestWISFacets,
		requestWISInventory,
		requestWISVehicle,
		requestClosestAccounts,
		useWIS,
		address,
		prefs,
		accountData
	) {
		// Account data for promos
		this.accountData = accountData;

		// Feature flags
		this.flags = flags;

		// Widget prefs
		this.prefs = prefs;

		// TODO: Remove when we have migrated to using WIS calls
		this.isWIS = useWIS;

		// User selections
		this.start = 0;

		// url params
		this.urlParams = {};

		this.closestAccounts = {
			data: [],
			appliedParams: {},
			prefs: {}
		};

		// Data returned from services, WIS if ffs are on or url param is present
		this.facets = {};
		this.appliedFilters = [];
		// window.DDC?.InvData?.inventory is defined by the preload code in /server/inline
		// this is only undefined if the preload FF is off or if the preload feature is not working
		this.inventory = window.DDC?.InvData?.inventory || {};
		this.featuredPromotion = {};
		this.spellcheck = '';
		this.appliedSpellcheck = false;
		this.originalSearchTerm = '';
		this.srpReady = false;
		this.sortBy = '';
		this.filterType = '';
		this.filterSearchEventData = {};

		this.TOPIC_PREFIX = 'ws-inv-data';
		this.FACETS_TOPIC = `${this.TOPIC_PREFIX}/facets`;
		this.INVENTORY_TOPIC = `${this.TOPIC_PREFIX}/inventory`;
		this.SPELLCHECK_TOPIC = `${this.TOPIC_PREFIX}/spellcheck`;
		this.SORT_BY_TOPIC = `${this.TOPIC_PREFIX}/sortBy`;
		this.CLOSEST_ACCOUNTS_TOPIC = `${this.TOPIC_PREFIX}/closestAccounts`;
		this.TOGGLE_LISTING_VIEW_TOPIC = `${this.TOPIC_PREFIX}/toggleListingView`;
		this.WINDOW_ID = 'inventory-data-bus1';
		this.FACETS_API_URL =
			window.DDC.WidgetData[this.WINDOW_ID].props.facetApiURL;
		this.INVENTORY_API_URL =
			window.DDC.WidgetData[this.WINDOW_ID].props.inventoryApiURL;
		this.SPELLCHECK_API_URL =
			window.DDC.WidgetData[this.WINDOW_ID].props.spellcheckApiURL;
		this.SEARCH_API_URL =
			window.DDC.WidgetData[this.WINDOW_ID].props.searchApiURL;
		this.COMPARE_PAGE =
			window.DDC.WidgetData[this.WINDOW_ID].props.comparePage;

		this.DEBOUNCE_WAIT = 100;

		this.useOriginalSearchTerm = false;
		this.forceOriginalSearch = false;

		if (this.prefs.geoLocationEnabled === 'true') {
			const sessionStorageLocation = JSON.parse(
				window.sessionStorage.getItem('session.location')
			);

			// Try to find users postal code
			const postalCode =
				window.localStorage.getItem('userLocation') ||
				sessionStorageLocation.userPostalCode;

			// TODO: remove tracking once we determine feature parity
			setNewRelicCustomAttribute(
				'srpSessionStoragePostalCode',
				sessionStorageLocation?.userPostalCode
			);
			setNewRelicCustomAttribute(
				'srpFallbackPostalCode',
				address?.postalCode
			);
			setNewRelicCustomAttribute(
				'srpWidgetDataPostalCode',
				window?.DDC?.WidgetData[this.WINDOW_ID]?.props?.geoData
					?.geoZip[0]
			);

			if (flags['enable-client-side-geolocation-ws-inv-data']) {
				this.geoData = {
					geoZip: [postalCode || address?.postalCode], // fallback to account postal code
					geoRadius: [this.prefs.defaultGeoDistRadius]
				};
				this.geoDataPrefs = {
					geoRadiusValues: this.prefs.geoRadiusValues.replaceAll(
						' ',
						''
					),
					defaultGeoDistRadius: this.prefs.defaultGeoDistRadius
				};
			} else if (
				window?.DDC?.WidgetData[this.WINDOW_ID]?.props?.geoData
			) {
				this.geoData =
					window.DDC.WidgetData[this.WINDOW_ID].props.geoData;
				this.geoDataPrefs = {
					...window.DDC.WidgetData[this.WINDOW_ID].props.geoDataPrefs,
					defaultGeoDistRadius: this.defaultGeoDistRadius
				};
			}
		}

		this.fetchers = {
			inventory: hysteriFetch('ws-inv-data-inventory-fetch'),
			facets: hysteriFetch('ws-inv-data-facets-fetch'),
			spellcheck: hysteriFetch('ws-inv-data-spellcheck-fetch')
		};

		this._requestResource = async (resourceType, eventType) => {
			const resourceBase = resourceType.toUpperCase();
			let queryString;

			if (
				this.spellcheck &&
				!this.forceOriginalSearch &&
				this.appliedSpellcheck
			) {
				queryString = this.getQueryString({
					search: [this.spellcheck]
				});
			} else if (
				Object.keys(this.urlParams).includes('search') &&
				resourceType !== 'spellcheck' &&
				!this.forceOriginalSearch &&
				!this.appliedSpellcheck &&
				this.flags['ws-inv-data-fetch-spellcheck']
			) {
				return {};
			} else {
				queryString = this.getQueryString();
			}

			const resourceUrl = `${
				this[`${resourceBase}_API_URL`]
			}${queryString}`;

			const resourceTopic = `${resourceBase}_TOPIC`;

			const responseHeaders = [];
			let response;

			this.publishEvent(`${this[resourceTopic]}-request`);

			try {
				if (!this.fetchers[resourceType]) {
					throw Error(
						`Cannot find fetch function for resource type: ${resourceType}`
					);
				}

				const startTime = Date.now();
				// This contains the call's raw Response which is used to get the response headers
				response = await this.fetchers[resourceType](
					resourceUrl,
					{
						method: 'GET',
						credentials: 'include',
						mode: 'no-cors'
					},
					{
						[wrappers.retry.contextKey]: {
							retries: this.flags['ws-inv-data-fetch-retries']
						},
						[wrappers.timeout.contextKey]: {
							timeout: this.flags['ws-inv-data-fetch-timeout']
						},
						[wrappers.fetch.contextKey]: {
							responseBodyType: null,
							validateResponse: null
						}
					}
				);

				const requestDuration = Date.now() - startTime;

				response.headers.forEach((value, key) =>
					responseHeaders.push({ key, value })
				);

				// get the body of the Response
				let responseBody;
				if (resourceType === 'spellcheck') {
					responseBody = await response.text();
					this.publishEvent(this[resourceTopic], [
						{
							timingData: {
								requestDuration,
								initialStartTime: startTime
							}
						}
					]);
					return responseBody;
				} else {
					responseBody = await response.json().then((data) => {
						return data;
					});
				}

				if (resourceType === 'facets') {
					if (flags['srp-track-fetch-resource-timing']) {
						trackFetchDuration('getFacets', 'GVM');
					}

					responseBody.facets = removeDeprecatedFacets(
						responseBody.facets
					);
					if (this.geoData) {
						setNewRelicCustomAttribute('srpLocationFacet', 'true');
						responseBody.facets.unshift(geoLocationObj);
					}

					// Adding SRP Total Count tracking for Products Analytics
					const invCount = responseBody?.totalCount;
					setNewRelicCustomAttribute('srpInvCount', invCount);

					this.appliedFilters = responseBody.filters || [];
					this[resourceType] = responseBody;
				}

				if (resourceType === 'inventory') {
					if (flags['srp-track-fetch-resource-timing']) {
						trackFetchDuration('getInventory', 'GVM');
					}

					// Nested destructuring of responseBody is used to isolate trackingData because it is only used in
					// datalayer and should not be passed to other widgets or written to InvData
					const {
						pageInfo: { trackingData, ...pageInfo },
						...resourceObject
					} = responseBody;
					this[resourceType] = { pageInfo, ...resourceObject };

					const samplePercent = flags['srp-test-package-data'];
					if (
						samplePercent > 0 &&
						Math.random() * 100 <= samplePercent
					) {
						const testingCount =
							responseBody?.inventory.length || 0;
						const testingPackages =
							responseBody?.inventory.filter(
								(item) => item.packageNames?.length > 0
							).length || 0;
						const testingPercent =
							testingCount === 0
								? 0
								: Math.round(
										(testingPackages / testingCount) * 100
								  ) / 100;

						addNewRelicPageAction(
							'WS INV DATA SAMPLE PACKAGE DATA',
							{
								testingMakes: [
									...new Set(
										trackingData.map((item) => item.make)
									)
								].toString(),
								testingCount,
								testingPackages,
								testingPercent
							}
						);
					}

					setDataLayerTracking(
						this.appliedFilters,
						this.sortBy,
						{
							pageInfo,
							trackingData
						},
						eventType,
						this.filterSearchEventData
					);
				}

				const requestProcessDuration = Date.now() - requestDuration;

				this.publishEvent(this[resourceTopic], [
					{
						timingData: {
							requestDuration,
							requestProcessDuration,
							initialStartTime: startTime
						}
					}
				]);
			} catch (_e) {
				this.publishEvent(`${this.TOPIC_PREFIX}/request-failed`);
				const errorMsg = _e.toString();
				const errorLogLabel = `WS INV DATA/GET ${resourceBase}/ERROR`;

				// send the request info to endpoint to log error on server
				// we don't need a response here
				logOnServer({
					logLabel: errorLogLabel,
					responseHeaders: JSON.stringify(responseHeaders),
					responseUrl: response.url,
					errorMsg
				});

				addNewRelicPageAction('WS INV DATA REQUEST FAILED', {
					resourceType,
					errorMsg
				});

				setNewRelicCustomAttribute(
					`${this.TOPIC_PREFIX}-request-failure`,
					'true'
				);
			}
			return {};
		};

		this._requestSpellcheck = () => {
			this.appliedSpellcheck = false;
			this._requestResource('spellcheck').then(
				(correctedSearchTerm = '') => {
					this.spellcheck = correctedSearchTerm;
					this.appliedSpellcheck = true;
					this.spellcheck = this.spellcheck.replace(/["']/g, '');
					if (!this.spellcheck) {
						this.useOriginalSearchTerm = false;
						this.publishEvent(
							`${this.TOPIC_PREFIX}/clearSpellcheckMsg`
						);
					} else {
						this.setLocalStorageForOriginalSearch();
					}
					this._requestFacets('spellcheck');
					this._requestInventory();
				}
			);
		};

		// Debounced request functions
		this._requestFacets = window._.debounce((eventType, eventData = {}) => {
			if (useWIS) {
				if (this.spellcheck && !this.forceOriginalSearch) {
					this.urlParams.search = this.spellcheck;
				} else if (this.forceOriginalSearch) {
					this.urlParams.search = [this.originalSearchTerm];
				}
				// eslint-disable-next-line no-unused-vars
				const { start, ...urlParams } = this.urlParams;
				requestWISFacets(
					urlParams,
					this.start,
					this.sortBy,
					eventType,
					eventData
				);
			} else {
				this._requestResource('facets', [{}]);
			}
		}, this.DEBOUNCE_WAIT);

		this._requestInventory = window._.debounce((eventType) => {
			const { inventoryFetchPromise } = window?.DDC?.InvData || {};
			if (useWIS) {
				// eslint-disable-next-line no-unused-vars
				const { sortBy, start, ...urlParams } = this.urlParams;
				requestWISInventory(urlParams, this.sortBy, this.start);
			} else if (inventoryFetchPromise) {
				inventoryFetchPromise.then((inventoryResponse) => {
					this.inventory = inventoryResponse;
					const {
						pageInfo: { trackingData, ...pageInfo }
					} = inventoryResponse;

					setDataLayerTracking(
						this.appliedFilters,
						this.sortBy,
						{
							pageInfo,
							trackingData
						},
						eventType,
						this.filterSearchEventData
					);
					this.publishEvent(`${this.TOPIC_PREFIX}/inventory`);
					// Clean up preload Promise
					window.DDC.InvData.inventoryFetchPromise = null;
				});
			} else {
				this._requestResource('inventory', eventType);
			}
		}, this.DEBOUNCE_WAIT);

		this._requestVehicle = async () => {
			this.featuredPromotion = await requestWISVehicle();
		};

		this._requestClosestAccounts = async () => {
			requestClosestAccounts(this.urlParams);
		};

		this._publishSortBy = window._.debounce(() => {
			this.publishEvent(this.SORT_BY_TOPIC, this.sortBy);
		}, this.DEBOUNCE_WAIT);

		this._loadInitialData();
	}

	registerFacetNotification(callback) {
		this.subscribeEvent(this.FACETS_TOPIC, callback);
	}

	registerInventoryNotification(callback) {
		this.subscribeEvent(this.INVENTORY_TOPIC, callback);
	}

	registerSortByNotification(callback) {
		this.subscribeEvent(this.SORT_BY_TOPIC, callback);
	}

	registerToggleListingView(callback) {
		this.subscribeEvent(this.TOGGLE_LISTING_VIEW_TOPIC, callback);
	}

	registerClosestAccountsNotification(callback) {
		this.subscribeEvent(this.CLOSEST_ACCOUNTS_TOPIC, callback);
	}

	getAccountData() {
		return this.accountData;
	}

	getFacets() {
		return this.facets;
	}

	getInventory() {
		return this.inventory;
	}

	getSpellcheck() {
		return this.spellcheck;
	}

	getPaging() {
		return this.inventory?.pageInfo || null;
	}

	getSortBy() {
		return this.sortBy;
	}

	getAppliedFilters() {
		return this.appliedFilters;
	}

	getUseOriginalSearchTerm() {
		return this.useOriginalSearchTerm;
	}

	getForceOriginalSearch() {
		return this.forceOriginalSearch;
	}

	getComparePage() {
		return this.COMPARE_PAGE;
	}

	getGeoData() {
		return this.geoData;
	}

	getGeoDataPrefs() {
		return this.geoDataPrefs;
	}

	getClosestAccounts() {
		return this.closestAccounts;
	}

	// TODO: Remove when we have migrated to using WIS calls
	getIsWIS() {
		return this.isWIS;
	}

	replaceSelections(urlParams, sortBy, start, updateBrowserHistory = true) {
		this.urlParams = urlParams;
		this.start = start;
		this.sortBy = sortBy;

		if (urlParams.geoZip) {
			this.urlParams.geoRadius = ['0'];
		}

		if (updateBrowserHistory) {
			this._updateBrowserHistory();
		}

		this._requestFacets();
		this._requestInventory();
	}

	clearForceOriginalSearch() {
		if (this.forceOriginalSearch) {
			window.localStorage.removeItem('forceOriginalSearch');
			this.forceOriginalSearch = false;
		}
	}

	clearOriginalSearchTerm() {
		if (this.originalSearchTerm) {
			window.localStorage.removeItem('originalSearchTerm');
			this.originalSearchTerm = '';
		}
	}

	clearFilters() {
		const urlParams = filterNonInventoryParams(
			this.urlParams,
			this.appliedFilters
		);

		this.clearSpellcheckMsg();
		this.clearForceOriginalSearch();
		this.clearOriginalSearchTerm();
		this.replaceSelections(urlParams, this.sortBy, 0 /* start */, true);
	}

	// clear the spellcheck so that it will allow to clear filters and replace selections
	clearSpellcheckMsg() {
		this.spellcheck = '';
		this.useOriginalSearchTerm = false;
		this.publishEvent(`${this.TOPIC_PREFIX}/clearSpellcheckMsg`);
	}

	setSortBy(value = '') {
		this.sortBy = value.split();

		this._updateBrowserHistory();
		this._requestInventory('sort');
		this._publishSortBy();
	}

	setLocalStorageForOriginalSearch() {
		if (this.urlParams.search) {
			window.localStorage.setItem(
				'originalSearchTerm',
				this.urlParams.search.toString()
			);
			setNewRelicCustomAttribute(
				'originalSearchTerm',
				this.originalSearchTerm
			);
			setNewRelicCustomAttribute('CorrectedSearchTerm', this.spellcheck);
			this.useOriginalSearchTerm = true;
		}
	}

	setLocalStorageForForceSearch() {
		window.localStorage.setItem('forceOriginalSearch', 'true');
		this.forceOriginalSearch = window.localStorage.getItem(
			'forceOriginalSearch'
		);
	}

	setOriginalSearchTerm() {
		this.useOriginalSearchTerm = true;
		this.spellcheck = '';
		if (this.originalSearchTerm) {
			setNewRelicCustomAttribute(
				'selectedOriginalSearchTerm',
				this.originalSearchTerm
			);
		}
		this.setLocalStorageForForceSearch();
		this.clearSpellcheckMsg();
		this._requestFacets();
		this._requestInventory();
	}

	spellcheckSearchTerm(event, facetInfo, contextEnabled) {
		if (!contextEnabled) {
			window.DDC.InvData.clearFilters();
		}
		if (facetInfo.search) {
			const searchValue = [];
			searchValue.push(facetInfo.search);
			this.urlParams.search = searchValue;
			window.history.pushState(
				{
					urlParams: this.urlParams
				},
				'',
				`${window.location.origin}${
					window.location.pathname
				}${this.getQueryString()}`
			);
		}
		this._requestSpellcheck();

		// Persisting geoZip and geoRadius
		const geoData = this.getAppliedFilters()
			.filter((key) => key.id.includes('geo'))
			.reduce((obj, item) => {
				return {
					...obj,
					[item.id]: item.values[0].value
				};
			}, {});

		const newFacetInfo = {
			...geoData,
			...facetInfo
		};

		Object.keys(newFacetInfo).forEach((name) => {
			window.DDC.InvData.setFilterSelections({
				filterName: name,
				filterValues: [newFacetInfo[name]],
				filterValue: newFacetInfo[name],
				element: event.target
			});
		});
	}

	/**
	 *
	 * @param {string} filterName Name of filter (ex: 'make')
	 * @param {string[]} filterValues Values of filter (ex: ['BMW', 'Toyota'])
	 * @param {string} filterValue most recently selected value of the filter, used for tracking
	 * @param {string} filterTitleText Optional Title label as the user sees it (ex: 'Fuel Type')
	 * @param {boolean} [checked=true] if filter is being added (true) or removed (false)
	 * @param {boolean} [isSlider=false] if filter is slider
	 * @param {boolean} [isBreadcrumb=false] if filter is breadcrumb
	 * @param element {object} element interacted with
	 */
	setFilterSelections({
		filterName,
		filterValues,
		filterValue,
		element,
		isSelected = true,
		isSlider = false,
		isChip = false,
		filterTitleText = ''
	}) {
		this.updateUrlParams({ filterName, filterValues });
		this.start = 0;
		this.filterType = filterName;
		this.filterSearchEventData = {
			clickedFacetName: filterName,
			clickedFacetValue: filterValue,
			clickedFacetTitleText: filterTitleText,
			checked: isSelected,
			isSlider,
			isBreadcrumb: isChip,
			element
		};

		// setting DDC.postalCode cookie when user changes zip code
		if (filterName === 'geoZip') {
			document.cookie = `DDC.postalCode=${filterValue}; path=/`;
			this.geoData.geoZip = filterValue;
		}

		this._updateBrowserHistory();

		// clear the spellcheck so that it will allow to clear filters
		if (!Object.keys(this.urlParams).includes('search')) {
			this.clearSpellcheckMsg();
			this.clearForceOriginalSearch();
			this.clearOriginalSearchTerm();
		}
		const eventType = filterName === 'search' ? 'search' : 'facet';
		this._requestFacets(eventType, this.filterSearchEventData);
		this._requestInventory(eventType);
	}

	updateUrlParams({ filterName, filterValues }) {
		// On redirect from a missing VDP, the corresponding make and model are automatically applied as filters
		// based on the the vdpUrl param. Here, we replace the vdpUrl param with params for the filters.
		if (Object.hasOwn(this.urlParams, 'vdpUrl')) {
			this.appliedFilters.forEach(({ id, values }) => {
				if (!Object.hasOwn(this.urlParams, id)) {
					this.urlParams[id] = [values[0]?.value];
				}
			});
			delete this.urlParams.vdpUrl;
		}

		// find a SB param key if it exists to add or remove facet
		const SBParamKey = findSBParamsMappingKey(filterName);
		const hasLegacyParam =
			SBParamKey.length > 0 &&
			Object.prototype.hasOwnProperty.call(this.urlParams, SBParamKey);

		if (!filterValues || !filterValues.length) {
			// Handle removing deprecated legacy `incentiveId` param due to conditional applied filter logic for `incentiveIds`
			if (
				filterName === 'incentiveIds' &&
				!this.urlParams.incentiveIds &&
				this.urlParams.incentiveId
			) {
				delete this.urlParams.incentiveId;
			} else {
				// Handle removing SB param from the url
				if (hasLegacyParam) {
					delete this.urlParams[SBParamKey];
				}
				delete this.urlParams[filterName];
			}
		} else {
			// Handle removing only SB param from the url with other facet choices selected
			if (
				hasLegacyParam &&
				!filterValues.includes(this.urlParams[SBParamKey][0])
			) {
				delete this.urlParams[SBParamKey];
			}
			this.urlParams[filterName] = filterValues;
		}
	}

	setStart(index, updateBrowserHistory = true) {
		this.start = index.toString().split();

		if (updateBrowserHistory) {
			this._updateBrowserHistory();
		}

		this._requestFacets();
		this._requestInventory();
	}

	_updateBrowserHistory() {
		window.history.pushState(
			{
				urlParams: this.urlParams,
				sortBy: this.sortBy,
				start: this.start
			},
			'',
			`${window.location.origin}${
				window.location.pathname
			}${this.getQueryString()}`
		);
	}

	getQueryStringObj(overrides = {}) {
		const urlParams = { ...this.urlParams };

		if (this.sortBy && !this.sortBy.includes('')) {
			urlParams.sortBy = this.sortBy;
		}
		if (this.start !== 0) {
			urlParams.start = this.start;
		}

		return {
			...urlParams,
			...overrides
		};
	}

	getQueryString(overrides = {}) {
		return urlizeFacetObj(this.getQueryStringObj(overrides));
	}

	/* eslint-disable class-methods-use-this */
	publishEvent(topic, ...params) {
		if (window && window.DDC && window.DDC.pubsub) {
			window.DDC.pubsub.publish(topic, ...params);
		}
	}

	subscribeEvent(topic, callback) {
		if (window && window.DDC && window.DDC.pubsub) {
			window.DDC.pubsub.subscribe(topic, callback);
		}
	}
	/* eslint-enable class-methods-use-this */

	_loadInitialData() {
		setNewRelicCustomAttribute('isSRP', 'true');
		const {
			sortBy = '',
			start = 0,
			...urlParams
		} = getUrlParams(
			`?${window.DDC.WidgetData[this.WINDOW_ID].props.params}`
		);

		const hasGeoParams = this.geoData
			? Object.keys(this.geoData).every((key) =>
					Object.keys(urlParams).includes(key)
			  )
			: null;
		if (this.geoData && !hasGeoParams) {
			this.urlParams = { ...urlParams, ...this.geoData };

			// Populating URL params on page load
			window.history.pushState(
				{
					urlParams: this.urlParams
				},
				'',
				`${window.location.origin}${
					window.location.pathname
				}${this.getQueryString()}`
			);
		} else {
			this.urlParams = urlParams;
		}
		this.srpReady = true;
		this.sortBy = sortBy;
		this.start = start;

		this.originalSearchTerm =
			window.localStorage.getItem('originalSearchTerm');
		this.forceOriginalSearch = window.localStorage.getItem(
			'forceOriginalSearch'
		);

		const searchInUrlParams = Object.keys(this.urlParams).includes('search')
			? Object.keys(this.urlParams).includes('search')
			: false;
		const searchValueInUrlParams = searchInUrlParams
			? this.urlParams.search.toString()
			: '';

		if (
			searchInUrlParams &&
			searchValueInUrlParams !== this.originalSearchTerm
		) {
			this.clearForceOriginalSearch();
			this.useOriginalSearchTerm = false;
		} else if (
			searchInUrlParams &&
			searchValueInUrlParams === this.originalSearchTerm
		) {
			this.useOriginalSearchTerm = false;
		} else if (searchInUrlParams) {
			this.useOriginalSearchTerm = true;
		} else {
			this.useOriginalSearchTerm = false;
			this.clearOriginalSearchTerm();
		}

		// check to see if the feature flag ws-inv-data-fetch-spellcheck is turn on
		// and there is search param in the url, it will call request spellcheck endpoint
		if (
			this.flags['ws-inv-data-fetch-spellcheck'] &&
			Object.keys(this.urlParams).includes('search')
		) {
			this._requestSpellcheck();
		} else {
			this._requestFacets();
			this._requestInventory();
			this._requestVehicle();
		}
		this._publishSortBy();
		this._requestClosestAccounts();
	}

	setUserLocation({ filterName, filterValues }) {
		this.updateUrlParams({ filterName, filterValues });
		this._updateBrowserHistory();
		this._requestClosestAccounts();
	}
}
