import { useSelector } from 'react-redux';
import { useRequestData, usePrefs, useFlags } from 'wsm-common-data';
import { isBrowser } from 'ws-scripts/modules/environment';
import { getUrlParams } from '../utilities/url-params';
import { InvData } from '../data';
import {
	getWISRequestBody,
	requestFacets,
	requestInventory,
	fetchWISVehicle
} from '../utilities/WISutils';
import { fetchClosestAccounts } from '../utilities/locationServiceUtils';

const Widget = () => {
	const flags = useFlags();

	const {
		moreRequestData,
		moreRequestData: { useWIS, address }
	} = useSelector((state) => state);
	const requestData = {
		...useRequestData(),
		...moreRequestData
	};

	const prefs = usePrefs();

	const requestWISFacets = (
		invParams,
		start,
		sortBy,
		eventType,
		eventData
	) => {
		const facetRequestBody = getWISRequestBody({
			requestData,
			prefs,
			invParams,
			start,
			flags
		});
		return requestFacets(
			invParams,
			sortBy,
			facetRequestBody,
			eventType,
			eventData
		);
	};

	const requestWISInventory = (invParams, sortBy, start) => {
		const inventoryRequestBody = getWISRequestBody({
			requestData,
			prefs,
			invParams,
			sortBy,
			start,
			flags
		});
		return requestInventory(invParams, inventoryRequestBody);
	};

	const requestWISVehicle = () => {
		if (!useWIS && requestData.featuredPromotion) {
			const vehicleRequestBody = getWISRequestBody({
				requestData,
				prefs,
				flags
			});

			return fetchWISVehicle(vehicleRequestBody);
		}
		return null;
	};

	const requestClosestAccounts = (invParams) => {
		const inventoryParameters = invParams || requestData.parameterMap || {};
		const { geoZip } = inventoryParameters;

		if (prefs.geoLocationEnhanced === 'true') {
			const { siteId, locale } = requestData;

			const { range } = inventoryParameters;
			const hasPostalCode = geoZip && !geoZip.includes('null');
			const closestAccountsBody = {
				accountId: siteId,
				locale,
				postalCode: hasPostalCode ? geoZip?.[0] : '',
				range: range?.[0],
				flags,
				prefs
			};
			return fetchClosestAccounts(closestAccountsBody);
		}
		return [];
	};

	if (isBrowser && !window.DDC?.InvData?.srpReady) {
		window.DDC.InvData = new InvData(
			flags,
			requestWISFacets,
			requestWISInventory,
			requestWISVehicle,
			requestClosestAccounts,
			useWIS,
			address,
			prefs,
			requestData.accountData
		);

		window.onpopstate = (e) => {
			if (e.state) {
				window.DDC.InvData.replaceSelections(
					e.state.urlParams,
					e.state.sortBy ? e.state.sortBy : '',
					e.state.start ? e.state.start : 0,
					false /* updateBrowserHistory */
				);
			} else {
				window.DDC.InvData.replaceSelections(
					getUrlParams(window.location.href),
					'',
					0,
					false
				);
			}
		};
	}
	return null;
};

export default Widget;
